const data = [
  {
    title: "WHAT IS A MILLIONAIRE MOGGS NFT ?",
    text: "IT'S NOT JUST DIGITAL ART, IT'S YOUR MEMBERSHIP TO OUR CLUB WITH MANY BENEFITS INCLUDING OUR FREE MOG LOTTERY, ACCESS TO MONEY MAD TROPICS METAVERSE PROJECT WHEN READY AND MORE.",
  },
  {
    title: "HOW DO THE PRIZES GET FUNDED ?",
    text: "EACH TIME A MILLIONAIRE MOG GETS RESOLD ON OPEN SEA 9% OF TOTAL SALE PRICE GOES DIRECTLY INTO THE COMMUNITY WALLET AND 1% GOES TO THE ARTISTS, DEVELOPERS, FOUNDERS, AND MONEY MAD GAMES LTD DEVELOPMENT.",
  },
  {
    title: "HOW OFTEN WILL THE LOTTERY AND GIVEAWAYS TAKE PLACE ?",
    text: "FROM THE FIRST DAY OF SELL OUT AS WELL AS PRE MINT GIVEAWAYS WE GUARANTEE THERE WILL BE 1 MAJOR PRIZE LOTTERY EVERY DAY FOR 7 DAYS WITH THE $1MILLION LOTTERY ON THE 7TH DAY THEN ALL OTHER LOTTERIES DEPEND ON HOW FAST THE ROYALTIES ROLL IN.",
  },
  {
    title: "WHEN WILL MOGLORDS RENTALS AND MONEY MAD TROPICS BE FINISHED ?",
    text: "MOGLORDS WILL BE READY WITHIN 3 TO 6 MONTHS AND MONEY MAD TROPICS WILL BE READY AROUND 4 -9 MONTHS OF FINAL MINT.",
  },
  {
    title: "HOW MUCH WILL MILLIONAIRE MOGS CLUB NFTS COST ?",
    text: "MOGLORDS WILL BE READY WITHIN 3 TO 6 MONTHS AND MONEY MAD TROPICS WILL BE READY AROUND 4 -9 MONTHS OF FINAL MINT.",
  },
  {
    title: "WHAT CAN I DO WITH MY MOG ?",
    text: "ANYTHING YOU WANT EACH NFT COMES WITH COMMERCIAL USAGE RIGHTS.",
  },
  {
    title: "WHAT BLOCKCHAIN IS MILLIONAIRE MOGS CLUB ON ?",
    text: "ETHEREUM BLOCK CHAIN.",
  },
];

export default data;
