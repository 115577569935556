import { useState } from "react";
import { MerkleTree } from 'merkletreejs';
import { keccak256 } from 'web3-utils';
import { useAccount, useContractRead } from 'wagmi';
import { useEffect } from "react";
import { FiX } from "react-icons/fi";
import { ethers } from 'ethers';
import ContractABI from "../../../contract/contractABI.json";


import { useModal } from "../../../utils/ModalContext";
import Button from "../../button";
import MintModalStyleWrapper from "./MintNow.style";
import mintImg from "../../../assets/images/icon/mint-img.png";
import hoverShape from "../../../assets/images/icon/hov_shape_L.svg";
import { whaleListAddresses } from '../../../assets/data/whitelists/whaleListAddresses';
import { privateListAddresses } from '../../../assets/data/whitelists/privateListAddresses';
import {
  maxSupplyCall,
  totalMintedCall,
  publicMintCostCall,
  publicMint,
  privateListMint,
  whaleListMint,
  whaleMintStartedCall,
  privateMintStartedCall,
  publicMintStartedCall,
  privateCostCall,
  whaleCostCall
} from "../../../contract/config";

window.Buffer = window.Buffer || require("buffer").Buffer; 

const MintNowModal = () => {
  const diviser = 1000000000000000000;

  const { mintModalHandle } = useModal();
  const { isConnected } = useAccount();
  
  const { data: privateCosting } = useContractRead({ ...privateCostCall });
  const { data: whaleCosting } = useContractRead({ ...whaleCostCall });
  const { data: publicMintStarted } = useContractRead({ ...publicMintStartedCall });
  const { data: privateMintStarted } = useContractRead({ ...privateMintStartedCall });
  const { data: whaleMintStarted } = useContractRead({ ...whaleMintStartedCall });
  const { data: maxSupplyData } = useContractRead({ ...maxSupplyCall });
  const { data: totalMintedData } = useContractRead({ ...totalMintedCall });
  const { data: publicMintCostData } = useContractRead({ ...publicMintCostCall });

  const [count, setCount] = useState(1);
  const [price, setPrice] = useState(0);

  const [totalSupply, setTotalSupply] = useState(9999);
  const [totalMinted, setTotalMinted] = useState(4583);
  const [publicStarted, setPublicStarted] = useState(false);
  const [privateStarted, setPrivateStarted] = useState(false);
  const [whaleStarted, setWhaleStarted] = useState(false);

  useEffect(() => {
    if (isConnected) {
      if (maxSupplyData) {
        setTotalSupply(maxSupplyData.toString());
      }
      if (totalMintedData) {
        setTotalMinted(totalMintedData.toString());
      }
      if(publicMintStarted) {
        setPublicStarted(true);
        setPrice((publicMintCostData.toString() / diviser) * count);
      }
      if(privateMintStarted) {
        setPrivateStarted(true);
        setPrice((privateCosting.toString() / diviser) * count);
      }
      if(whaleMintStarted) {
        setWhaleStarted(true);
        setPrice((whaleCosting.toString() / diviser) * count);
      }
    }
  })

  const generateProof = (addresses, account) => {  
    const buf2hex = x => '0x'+x.toString('hex');
    const leaves = addresses.map(x => keccak256(x))
    const tree = new MerkleTree(
      leaves, keccak256, { sortPairs: true }
    );
    const leaf = keccak256(account)
    const proof = tree.getProof(leaf).map(
      x => buf2hex(x.data)
    );

    return proof;
  }

  const decreaseCount = () => {
    let currcount = count - 1;
    let currprice = price * currcount;
    if (currcount < 1) {
      currcount = 1;
    }
    setCount(currcount);
    setPrice(currprice);
  }

  const increaseCount = () => {
    let currcount = count + 1;
    let currprice = price * currcount;
    setCount(currcount);
    setPrice(currprice);
  }

  const mintNow = async () => {
    if (isConnected) {
      if(publicStarted) {
        const contractAddress = "0x00c01dFb9D6304DCF65Aaea134862dAd57741bC0";
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(contractAddress, ContractABI, signer);
        await publicMint(count,nftContract);
      } 
      if(privateStarted) {
        const contractAddress = "0x00c01dFb9D6304DCF65Aaea134862dAd57741bC0";
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(contractAddress, ContractABI, signer);
        const accounts = await provider.send("eth_requestAccounts", []);
        const account = accounts[0];
        const proof = generateProof(privateListAddresses(), account,  nftContract);
        if(proof.length > 0) {
          console.log(proof, count)
          await privateListMint(proof, count);
        } else {
          console.log('You are not on the whitelist')
        }
      } 
      if(whaleStarted) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contractAddress = "0x00c01dFb9D6304DCF65Aaea134862dAd57741bC0";
        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(contractAddress, ContractABI, signer);
        const accounts = await provider.send("eth_requestAccounts", []);
        const account = accounts[0];
        const proof = generateProof(whaleListAddresses(), account, nftContract);
        if(proof.length > 0) {
          console.log(proof, count)
          await whaleListMint(proof, count);
        } else {
          console.log('You are not on the whitelist')
        }
      }
    }
  }

  return (
    <>
      <MintModalStyleWrapper className="modal_overlay">
        <div className="mint_modal_box">
          <div className="mint_modal_content">
            <div className="modal_header">
              <h2>Collect YOUR NFT before end</h2>
              <button onClick={() => mintModalHandle()}>
                <FiX />
              </button>
            </div>
            <div className="modal_body text-center">
              <div className="mint_img">
                <img src={mintImg} alt="blockgeni3 nft mint" />
              </div>
              <div className="mint_count_list">
                <ul>
                  <li>
                    <h5>Remaining</h5>
                    <h5>
                      {totalMinted}/<span>{totalSupply}</span>
                    </h5>
                  </li>
                  <li>
                    <h5>Price</h5>
                    <h5>{parseFloat(price).toFixed(3)} ETH</h5>
                  </li>
                  <li>
                    <h5>Quantity</h5>
                    <div className="mint_quantity_sect">
                      <button onClick={() => decreaseCount()}>-</button>
                      <input
                        type="text"
                        id="quantity"
                        value={count}
                        onChange={(e) => setCount(e.target.value)}
                      />
                      <button onClick={() => increaseCount()}>+</button>
                    </div>
                    <h5>
                      <span>{parseFloat(price).toFixed(3)}</span> ETH
                    </h5>
                  </li>
                </ul>
              </div>
              <div className="modal_mint_btn">
                <Button lg variant="mint" onClick={() => mintNow()}>
                  Mint Now
                </Button>
              </div>
            </div>

            <div className="modal_bottom_shape_wrap">
              <span className="modal_bottom_shape shape_left">
                <img src={hoverShape} alt="blockgeni3 nft hover shape" />
              </span>
              <span className="modal_bottom_shape shape_right">
                <img src={hoverShape} alt="blockgeni3 nft hover shape" />
              </span>
            </div>
          </div>
        </div>
      </MintModalStyleWrapper>
    </>
  );
};

export default MintNowModal;
