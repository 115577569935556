import aboutThumb1 from "../../images/blog/ab-img.png";
import aboutThumb2 from "../../images/blog/ab-img2.png";
import aboutIcon from "../../images/icon/ab-icon-image.png";

const data = {
  aboutDescription1:
    "Millionaire Mogs Club is the first project of money mad games Ltd uk. We created money mad games to create a more community driven project that gives members more than a pfp to brag about. Not only is your NFT your free mog lottery number but it's your membership to a different kind of club, as we grow you can expect more than we currently have planned as one door opens another one usually opens after that as we develop and grow with recruitment and partnerships with 90% of resale royalties and 75% of mint sale cash going back into the club. ",
  aboutDescription2:
    "Our next 2 projects each bring their unique game types plus all eventually joins together by slicing a little % of each collection's royalties to make a super jackpot of $5 Million starting from once all projects up and running so join us early and strap in because it's going to get wild! Doxxed and accountable uk registered company money mad games Ltd, ancoats, Manchester, UK.",
  artists: [
    {
      thumb: aboutThumb1,
      icon: aboutIcon,
      name: "Berneice Tran",
      role: "Artist",
    },
    {
      thumb: aboutThumb2,
      icon: aboutIcon,
      name: "Leana Rosa",
      role: "Co-Artist",
    },
  ],
};

export default data;
