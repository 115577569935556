import { FaLinkedinIn, FaTwitter, FaInstagram } from "react-icons/fa";

import avatarThumb1 from "../../images/team/Dempsey.jpg";
import avatarThumb2 from "../../images/team/Emma.jpg";
import avatarThumb3 from "../../images/team/Daniyal.jpg";
import avatarThumb4 from "../../images/team/Dave.jpg";
import avatarThumb5 from "../../images/team/MW.jpg";
import avatarThumb6 from "../../images/team/Hollie.jpg";

const data = [
  {
    avatar: avatarThumb1,
    name: "DEMPSEY P",
    designation: "FOUNDER, MODERATOR, TEAM LEADER",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
      {
        icon: <FaInstagram />,
        url: "#",
      },
    ],
  },
  {
    avatar: avatarThumb2,
    name: "EMMA W",
    designation: "ARTIST, MARKETING SPECIALIST, GAME MODERATOR",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
      {
        icon: <FaInstagram />,
        url: "#",
      },
    ],
  },
  {
    avatar: avatarThumb3,
    name: "Blockgeni3",
    designation: "SMART CONTRACT & WEB 3 SPECIALISTS",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
      {
        icon: <FaInstagram />,
        url: "#",
      },
    ],
  },
  {
    avatar: avatarThumb4,
    name: "DAVE SWEETMORE",
    designation: "MONEY MAD GAMES HOST, BRAND AMBASSADOR, ALL ROUND TOP BLOKE",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
      {
        icon: <FaInstagram />,
        url: "#",
      },
    ],
  },
  {
    avatar: avatarThumb5,
    name: "M.W",
    designation: "JOINT ARTIST, LEAD GAME DEVELOPMENT, MODERATOR",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
      {
        icon: <FaInstagram />,
        url: "#",
      },
    ],
  },
  {
    avatar: avatarThumb6,
    name: "HOLLIE P",
    designation: "FOUNDER, ARTIST, MODERATOR",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
      {
        icon: <FaInstagram />,
        url: "#",
      },
    ],
  }
];

export default data;
