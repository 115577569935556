import { useState } from "react";
import { useModal } from "../../../utils/ModalContext";
import { useAccount } from 'wagmi';
import { useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue } from "firebase/database";
import { FiX } from "react-icons/fi";
import { config } from "../../../config.js";
import Wheel from '../../../components/section/Wheel';

import hoverShape from "../../../assets/images/icon/hov_shape_L.svg";
import SpinStyleWrapper from "./SpinToWin.style";

const SpinToWinModal = () => {
  let initialize = false;

  const winnerText = "Winner 🥳 !";
  const loserText = "Sorry, No WL ☹️";
  const [wheelActive, setWheelActive] = useState(false);
  const { spinModalHandle } = useModal();
  const { address, isConnecting, isConnected, isDisconnected } = useAccount();
  const places = [
    winnerText, loserText, loserText, loserText, loserText, loserText, loserText, loserText, loserText, loserText,
    loserText, loserText, loserText, loserText, loserText, loserText, loserText, loserText, loserText, loserText
  ];

  const date = new Date();
  const folderPathDate = date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();
  const table = "spins/" + folderPathDate + "/" + address;

  initializeApp(config);

  const db = getDatabase();
  const data = ref(db, table);
  const winData = ref(db, "wins");

  const getData = () => {
    onValue(data, snapshot => {
      setWheelActive(snapshot.val() === null);
      initialize = true;
    });
  };

  useEffect(() => {
    getData();
  });

  return (
    <>
      <SpinStyleWrapper className="modal_overlay">
        <div className="mint_modal_box spin">
          <div className="mint_modal_content">
            <div className="modal_header">
              <h2>Spin For A Chance To Win Whitelist</h2>
              <button onClick={() => spinModalHandle()}>
                <FiX />
              </button>
            </div>
            <div className="modal_body text-center">
              <Wheel items={places} data={data} winData={winData} wheelActive={wheelActive} />
            </div>

            <div className="modal_bottom_shape_wrap">
              <span className="modal_bottom_shape shape_left">
                <img src={hoverShape} alt="blockgeni3 nft hover shape" />
              </span>
              <span className="modal_bottom_shape shape_right">
                <img src={hoverShape} alt="blockgeni3 nft hover shape" />
              </span>
            </div>
          </div>
        </div>
      </SpinStyleWrapper>
    </>
  );
};

export default SpinToWinModal;
