import nftImg1 from "../images/mogs/m1.png";
import nftImg2 from "../images/mogs/m2.png";
import nftImg3 from "../images/mogs/m3.png";
import nftImg4 from "../images/mogs/m4.png";
import nftImg5 from "../images/mogs/m5.png";
import nftImg6 from "../images/mogs/m6.png";
import nftImg7 from "../images/mogs/m7.png";
import nftImg8 from "../images/mogs/m8.png";
import nftImg9 from "../images/mogs/m9.png";
import nftImg10 from "../images/mogs/m10.png";
import nftImg11 from "../images/mogs/m11.png";
import nftImg12 from "../images/mogs/m12.png";

const data = [
  nftImg1,
  nftImg2,
  nftImg3,
  nftImg4,
  nftImg5,
  nftImg6,
  nftImg7,
  nftImg8,
  nftImg9,
  nftImg10,
  nftImg11,
  nftImg12,
];

export default data;
