import styled from "styled-components";

const FeaturesStyleWrapper = styled.section`
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #0e0e0e;

  .features_items_row {
    row-gap: 30px;
    margin-top: 60px;
  } 
 
`;

export default FeaturesStyleWrapper;
