import { ethers } from 'ethers';
import ContractABI from "./contractABI.json";

const contractAddress = "0x00c01dFb9D6304DCF65Aaea134862dAd57741bC0";

//contract configuration
export const contractConfig = {
    address: contractAddress,
    abi: ContractABI
}

// Check public mint status
export const publicMintStartedCall = {
    ...contractConfig,
    functionName: 'publicMintStarted',
    watch: true
}

// Check private mint status
export const privateMintStartedCall = {
    ...contractConfig,
    functionName: 'privateMintStarted',
    watch: true
}

// Check whale mint status
export const whaleMintStartedCall = {
    ...contractConfig,
    functionName: 'whaleMintStarted',
    watch: true
}

// Check whale cost
export const whaleCostCall = {
    ...contractConfig,
    functionName: 'whaleListCost',
    watch: true
}

// Check private cost
export const privateCostCall = {
    ...contractConfig,
    functionName: 'privateListCost',
    watch: true
}

// Total minted 
export const totalMintedCall = {
    ...contractConfig,
    functionName: 'totalSupply',
    watch: true,
}

//maximum mint per wallet
export const maxMintPerWalletCall = () => {
    return 10;
}

//maximum supply
export const maxSupplyCall = {
    ...contractConfig,
    functionName: 'MAX_SUPPLY',
    watch: true,
}

//public mint cost
export const publicMintCostCall = {
    ...contractConfig,
    functionName: 'cost',
    watch: true,
}

//get price to pay
export const priceToPay = (cost) => {
    return parseFloat(cost) / 1000000000000000000;
}

// Public mint
export const publicMint = async (mintAmount, nftContract) => {
    const mintCost = await nftContract.cost.call();
    const finalCost = priceToPay(mintCost);
    const absoluteFinalCost = parseFloat(finalCost) * parseInt(mintAmount);

    console.log(absoluteFinalCost)

    let txnHash = nftContract.mint(parseInt(mintAmount), {
        gasLimit: "285000",
        value: ethers.utils.parseEther(absoluteFinalCost.toString())
    })

    return txnHash;
}

// Private mint
export const privateListMint = async (proof, mintAmount, nftContract) => {
    const mintCost = await nftContract.privateListCost.call();
    const finalCost = priceToPay(mintCost.toString());
    const absoluteFinalCost = parseFloat(finalCost) * parseInt(mintAmount);

    let txnHash = await nftContract.privateListMint(proof, parseInt(mintAmount), {
        gasLimit: "285000",
        value: ethers.utils.parseEther(absoluteFinalCost.toString())
    })

    return txnHash;
}

// Whale mint
export const whaleListMint = async (proof, mintAmount, nftContract) => {
    const mintCost = await nftContract.whaleListCost.call();
    const finalCost = priceToPay(mintCost);
    const absoluteFinalCost = parseFloat(finalCost) * parseInt(mintAmount);

    let txnHash = await nftContract.whaleListMint(proof, parseInt(mintAmount), {
        gasLimit: "285000",
        value: ethers.utils.parseEther(absoluteFinalCost.toString())
    })

    return txnHash;
}