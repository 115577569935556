import { FiCheck, FiMinus } from "react-icons/fi";
import thumb1 from "../../images/mogs/m10.png";
import thumb2 from "../../images/mogs/m3.png";

const data = [
  {
    title: "PRE MINT ROADMAP",
    thumb: thumb1,
    features: [
      {
        icon: <FiCheck />,
        text: "AGGRESSIVE MARKETING AND EXCLUSIVE PARTNERSHIPS WITH BIG BRAND COMPANIES.",
      },
      {
        icon: <FiCheck />,
        text: "NFT AND WHITELIST GIVEAWAYS",
      },
      {
        icon: <FiCheck />,
        text: "EXCLUSIVE 200 PLACES ON OUR WHALE MINT LIST TO PAY ALL DEPOSITS AND $100,000 2 WEEKS AD CAMPAIGN BEFORE WHITELIST AND PUBLIC MINT AND THESE CLUB MEMBERS GET EARLY ACCESS TO ALL FUTURE PROJECTS",
      },
      {
        icon: <FiCheck />,
        text: "WHITELIST WILL TAKE PLACE 24 HOURS BEFORE PUBLIC MINT.",
      },
      {
        icon: <FiCheck />,
        text: "ALL MOGS SOLD IN WHALE MINT AND WHITELIST MINT WILL BE ENTERED INTO OUR $100,000 LIVE AND FREE MOG LOTTERY HOSTED BY A MANCHESTER LEGEND DAVE SWEETMORE, TAKING PLACE 1 HOUR BEFORE PUBLIC MINT GOES LIVE.",
      }
    ],
  },

  {
    title: "POST MINT ROADMAP",
    thumb: thumb2,
    features: [
      {
        icon: <FiCheck />,
        text: "FROM WHEN THE LAST MILLIONAIRE MOGS CLUB NFT SELLS WE WILL BE ANNOUNCING THE $1MILLION (IN ETH) FREE MOG LOTTERY WILL TAKE PLACE IN 1 WEEK LIVE ON OUR SOCIAL MEDIA PLATFORM WITH OUR HOST DAVE SWEETMORE AND CELEBRITY GUEST(TO BE ANNOUNCED)",
      },
      {
        icon: <FiCheck />,
        text: "DAY 1 OF SELL OUT= OUR FIRST LIVE FREE MOG LOTTERY WITH A STAR PRIZE OF A $350,000 SUPERCAR PROVIDED BY ONE OF OUR BRAND PARTNERS! DAY2 = $120,000 WATCH FREE MOG LOTTERY FROM ANOTHER OF OUR BRAND PARTNERS! DAY 3 IS A $60,000 CONCIERGE HOLIDAY FROM ANOTHER OF OUR BRAND PARTNERS! DAY 4 = $350,000 SUPERCAR AGAIN! DAY 5 IS A $120,000 WATCH AGAIN AND DAY 6 IS ANOTHER $60,000 CONCIERGE HOLIDAY WITH ALL OUR BRAND PARTNERS OFFERING A CASH ALTERNATIVE! THEN ON DAY 7 IS OUR FREE AND LIVE $1MILLION FREE MOG LOTTERY ,THEN THE ROYALTIES PAY FOR BOTH THESE GAMES TO CONTINUE TO FLURISH WITH 40% OF RESALE ROYALTIES GOING DIRECTLY INTO THE $1M JACKPOT SHOWN AS A GRAPH ON OUR WEBSITE AND THE REST PAYING FOR MORE REGULAR LOTTERYS AND THE RUNNING OF THE MILLIONAIRE MOGS CLUB !",
      },
      {
        icon: <FiCheck />,
        text: "MARKETING- WE AIM TO MAKE MILLIONAIRE MOGS CLUB ONE OF THE MOST VISIBLE NFT COLLECTIONS ON THE MARKET WITH ONE OF THE MOST GENEROUS ROYALTY GIVEN BACK AT 90%, GIVING US THE EDGE ON OTHER CLUBS!",
      },
      {
        icon: <FiCheck />,
        text: "METAVERSE- HUGE INVESTMENT INTO OUR METAVERSE PLATFORM MONEY MAD TROPICS THAT WILL HAVE FEATURES LIKE NO OTHER INCLUDING P2E GAMES AND EXCLUSIVE DISCOUNT SHOPPING, FREE ENTERTAINMENT AND MUCH MUCH MORE AS WE’RE ONLY JUST AT THE START OF OUR JOURNEY.",
      },
      {
        icon: <FiCheck />,
        text: "PASSIVE INCOME- WE AIM TO HAVE AS MANY RISK FREE FORMS OF PASSIVE INCOMES FROM YOUR MOG AS POSSIBLE AND AS WELL AS P2E GAMES AND TASKS WITH REAL WORLD PRIZES AS WELL AS A CHANCE TO MAKE A PASSIVE INCOME FROM BECOMING A ‘MOGLORD’! WE WILL BE DEVELOPING A WEBSITE WERE MILLIONAIRE MOG HOLDERS CAN RENT OUT THEIR MOG DAILY, WEEKLY, MONTHLY, AND CAN RENT OUT 25%, 50%, 75% OR 100% OF YOUR MOG! MEANING IF YOU RENT OUT 50% OF YOUR MOG THEN IT WINS ONE OF OUR MOGS LOTTERY'S THEN THE MOGLORD AND THE RENTER WOULD SHARE THE CASH OFFER 50/50 AND SO ON! WITH THE POSSIBILITY OF EARNING OVER $2000 PER MOG PER YEAR JUST IN RENT ALONE.",
      },
      {
        icon: <FiCheck />,
        text: "FUTURE OF MONEY MAD GAMES- MILLIONAIRE MOGS CLUB IS OUR FIRST PROJECT TO GIVE US THE FUNDING TO BUILD AN INTERACTIVE GAMING AND VIRTUAL HUB THAT HELPS ALL OUR COMMUNITY LIVE A BETTER LIFE, OR EVEN BETTER THAN IT ALREADY IS 😉",
      },
    ],
  },
];

export default data;
