export const data1 = [
  {
    title: "WHAT IS A MILLIONAIRE MOGGS NFT ?",
    text: "IT'S NOT JUST DIGITAL ART, IT'S YOUR MEMBERSHIP TO OUR CLUB WITH MANY BENEFITS INCLUDING OUR FREE MOG LOTTERY, ACCESS TO MONEY MAD TROPICS METAVERSE PROJECT WHEN READY AND MORE.",
  },
  {
    title: "HOW DO THE PRIZES GET FUNDED ?",
    text: "EACH TIME A MILLIONAIRE MOG GETS RESOLD ON OPEN SEA 9% OF TOTAL SALE PRICE GOES DIRECTLY INTO THE COMMUNITY WALLET AND 1% GOES TO THE ARTISTS, DEVELOPERS, FOUNDERS, AND MONEY MAD GAMES LTD DEVELOPMENT.",
  },
  {
    title: "HOW OFTEN WILL THE LOTTERY AND GIVEAWAYS TAKE PLACE ?",
    text: "FROM THE FIRST DAY OF SELL OUT AS WELL AS PRE MINT GIVEAWAYS WE GUARANTEE THERE WILL BE 1 MAJOR PRIZE LOTTERY EVERY DAY FOR 7 DAYS WITH THE $1MILLION LOTTERY ON THE 7TH DAY THEN ALL OTHER LOTTERIES DEPEND ON HOW FAST THE ROYALTIES ROLL IN.",
  },
];
export const data2 = [
  {
    title: "Where we can buy and sell NFts ?",
    text: "It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur cites of the word in classical literature.",
  },
  {
    title: "How secure is this token ?",
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
  {
    title: "What is your contract address ?",
    text: "It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur cites of the word in classical literature.",
  },
];
