import aboutCardIcon1 from "../images/icon/aboutus_card_icon1.svg";
import aboutCardIcon2 from "../images/icon/aboutus_card_icon2.svg";
import aboutCardIcon3 from "../images/icon/aboutus_card_icon3.svg";
import aboutCardIcon4 from "../images/icon/aboutus_card_icon4.png";
import aboutCardIcon5 from "../images/icon/aboutus_card_icon5.png";
import aboutCardIcon6 from "../images/icon/aboutus_card_icon6.png";

const data = [
  {
    icon: aboutCardIcon4,
    title: "The Free Mog Lottery",
    text: "The first $1 Million free mog lottery is funded from the mint cash then 40% of the 10% royalties goes directly into the $1 Million lottery fund shown and updated daily as a graph on our website. ",
  },
  {
    icon: aboutCardIcon5,
    title: "Mog Donation",
    text: "As well as donating 50 x7 bonus mogs and 1 x10 bonus mogs to charity we will be supporting lots of homeless charities and food banks worldwide with the help of our club members giving us leads on community hubs in need! ",
  },
  {
    icon: aboutCardIcon6,
    title: "Giving Back",
    text: "We will be donating regularly the mustard tree a charity that helps feed the community from the homeless to hard working locals who like most people in the world are struggling to put food on the table and this help means so much to them.",
  },
  {
    icon: aboutCardIcon1,
    title: "Constant Rewards",
    text: "The other 60% of the 10% royalties pays for more regular free lotteries with prizes like top of the range supercars, villas, watches, holidays, cash and the running and development of the mogs club ecosystem.",
  },
  {
    icon: aboutCardIcon2,
    title: "Transparency",
    text: 'We will be one of the most transparent clubs in cyberspace with regular audits of all funds and promised investments.and all club investments will be shouted from the rooftops with receipts in hand! Or shown or displayed on our socials.',
  },
  {
    icon: aboutCardIcon3,
    title: "Metaverse Ready",
    text: "When money mad tropics is completed the first 1000 nfts sold will be airdropped 1 random money mad tropics nft for free as gratitude for helping us grow! All MMG nfts will have access to money mad tropics and all it's perks for free!",
  },
];

export default data;
