import shapeIcon from "../images/icon/mint_live_icon.svg";

const data = [
  {
    icon: shapeIcon,
    text: "Whalelist Mint Now",
  },
  {
    icon: shapeIcon,
    text: "Private Mint Soon",
  },
  {
    icon: shapeIcon,
    text: "Public Mint Soon",
  },
  {
    icon: shapeIcon,
    text: "Whalelist Mint Now",
  },
  {
    icon: shapeIcon,
    text: "Private Mint Soon",
  },
  {
    icon: shapeIcon,
    text: "Public Mint Soon",
  },
  {
    icon: shapeIcon,
    text: "Whalelist Mint Now",
  },
  {
    icon: shapeIcon,
    text: "Private Mint Soon",
  },
  {
    icon: shapeIcon,
    text: "Public Mint Soon",
  },
  {
    icon: shapeIcon,
    text: "Whalelist Mint Now",
  },
  {
    icon: shapeIcon,
    text: "Private Mint Soon",
  },
  {
    icon: shapeIcon,
    text: "Public Mint Soon",
  }
];

export default data;
