import React from "react";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set } from "firebase/database";
import { config } from "../../../config.js";

import "./index.css";

export default class Wheel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
      error: "",
    };
    this.selectItem = this.selectItem.bind(this);
  }

  selectItem() {
    const date = new Date() + "";

    if (this.state.selectedItem === null) {
      if (this.props.wheelActive) {
        this.setState({ error: "" });

        const selectedItem = Math.floor(Math.random() * this.props.items.length);

        if (this.props.onSelectItem) {
          this.props.onSelectItem(selectedItem);
        }

        set(this.props.data, {
          Date: date,
          Result: this.props.items[selectedItem],
        });

        if (this.props.items[selectedItem] === "Winner !") {
          set(this.props.winData, {
            Address: localStorage.getItem("address"),
          });
        }

        this.setState({ selectedItem });
      } else {
        this.setState({ error: "You have already spun today, come back tomorrow for another chance !" });
      }
    } else {
      this.setState({ selectedItem: null });
      setTimeout(this.selectItem, 500);
    }
  }

  render() {
    const { selectedItem } = this.state;
    const { error } = this.state;
    const { items } = this.props;
    const { wheelActive } = this.props;
    const wheelVars = {
      "--nb-item": items.length,
      "--selected-item": selectedItem,
    };
    const spinning = selectedItem !== null ? "spinning" : "";
    const wheelDisplay = wheelActive ? "inactive" : "active";

    return (
      <div>
        <p style={{ color: "white" }}>{error}</p>
        <div className="wheel-container">
          <div className={`wheel ${spinning} ${wheelDisplay}`} style={wheelVars} onClick={this.selectItem}>
            {items.map((item, index) => (
              <div className="wheel-item" key={index} style={{ "--item-nb": index }}>
                {item}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
